$(function () {
  $(".section.more.collapse").each(function () {

    $(this).on('shown.bs.collapse', function () {
      if ($(this).height() < $(window).height()) {
        $('html, body').stop().animate({
          scrollTop: $(this).offset().top - ($(window).height() - $(this).height())
        }, 1500, 'easeInOutExpo');
      } else {
        $('html, body').stop().animate({
          scrollTop: $(this).offset().top
        }, 1500, 'easeInOutExpo');
      }
    });

  });
});