// Responsive breakpoints
var smBreakpoint = 768;
var mdBreakpoint = 992;
var lgBreakPoint = 1200;

$(window).load(function () {

    if ($('#contact-form').find('.help-inline').length > 0) {

        $('html, body').stop().animate({

            scrollTop: $('#contact-form').offset().top - 120

        }, 1500, 'easeInOutExpo');

    }

    if ($('#contact-form-confirm').length > 0) {

        $('html, body').stop().animate({

            scrollTop: $('#contact-form-confirm').offset().top - 120

        }, 1500, 'easeInOutExpo');

    }

    function showPrivacySection() {
        var targetId = $('#13a1d809-c89b-4712-970b-2ffdfafff5c4-section a.collapse-button[data-toggle="collapse"]').attr('aria-controls');
        var target = $('#' + targetId);

        target.collapse('show').one('shown.bs.collapse', function (event) {
            var privacyHeadline = $('#13a1d809-c89b-4712-970b-2ffdfafff5c4-section-more > div > div > div > div > div > div > div:nth-child(4)');
            $([document.documentElement, document.body]).animate({
                scrollTop: (privacyHeadline.offset().top - 150)
            }, 2000);
        })
    }

    setTimeout(function () {
        var consentWindow = $('div.cc-window[role="dialog"]');
        if (consentWindow.length) {
            var learnMoreLink = consentWindow.find('a.cc-link');

            learnMoreLink.attr('href', '#');
            learnMoreLink.on('click', function (event) {
                event.preventDefault();
                showPrivacySection();
            });
        }
    }, 100);

    function contactFormLinkListener() {
        var link = $('#data-privacy-contact-form-link');

        if (link.length) {
            link.on('click', function (event) {
                event.preventDefault();
                showPrivacySection();
            });
        } else {
            setTimeout(contactFormLinkListener, 100);
        }
    }

    contactFormLinkListener();
});


$(document).ready(function () {

    $('.section.more').on('shown.bs.collapse', function () {
        if ($(this).find('.google-map-canvas').length) {
            initGoogleMaps();
        }
    });

    initAjaxForm();
    reinitSwipers();

    $('.modal').on('shown.bs.modal', function (e) {
        if ($(this).find('.swiper-container').length > 0) {
            var mySwiper2 = initSwiperForModal($(this).find('.modal-swiper-container').get(0));
            var a = $(this).find('.modal-swiper-container').height() / 2 + 30;

            $(this).find('.modal-swiper-button-next').css({
                "top": a.toString().concat('px'),
                "display": "block"
            });
            $(this).find('.modal-swiper-button-prev').css({
                "top": a.toString().concat('px'),
                "display": "block"
            });

        }
    });

    $('main .swiper-container').first().css('max-height', '100vh');
    // $('main .swiper-container').first().css('min-height', '900px');

    $('.mini-swiper .swiper-button-next, .mini-swiper .swiper-button-prev').on('click', function (event) {
        removeCursorSelection();
    });

    $('a.page-scroll').on('click', function (event) {
        event.preventDefault();
        // event.stopImmediatePropagation();

        var targetSelector = $(this).attr('href');
        var scrollTo = 0;

        if (targetSelector !== '#' && targetSelector !== '#PageTop') {
            scrollTo = $(targetSelector).offset().top - $('body > header > nav').outerHeight();
        }

        var currentScroll = $('html, body').get(0).scrollTop;
        var scrollDuration = Math.abs(currentScroll - scrollTo);

        if (scrollDuration < 500) {
            scrollDuration = 500;
        }

        $('html, body').stop().animate({
            scrollTop: scrollTo
        }, scrollDuration, 'swing');

        return false;
    });

    if (window.neos !== undefined) {
        document.addEventListener('Neos.NodeSelected', function (event) {
            console.log('Neos.NodeSelected:', event);

            var node = event.detail.node;
            if (node.get('nodeType') === 'Galabau.MainWebsite:SwiperItem') {
                var element = event.detail.element;
                var container = $(element).parents('.swiper-container').get(0);
                var slider = container._swiper;
                var elementIndex = $(this).data('index');
                var slides = slider.slides;
                
                for (var key in slides) {
                    if (slides.hasOwnProperty(key)) {
                        var slide = slides[key];
                        if (slide === element) {
                            elementIndex = parseInt(key);
                            break;
                        }
                    }
                }
                
                slider.slideTo(elementIndex);
            }
        }, false);
    }
});

function removeCursorSelection() {
    if (window.getSelection) {
        if (window.getSelection().empty) { // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) { // Firefox
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) { // IE?
        document.selection.empty();
    }
}


function initSwiperForModal(el) {
    var swiper = new Swiper(el, {
        pagination: '.modal-swiper-pagination',
        nextButton: '.modal-swiper-button-next',
        prevButton: '.modal-swiper-button-prev',
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 30,
        loop: true,
        simulateTouch: false
    });

    return swiper;
}



window.initButtonClickHandler = function () {

    $('.ajax-form button[type="submit"]').on('click', function () {
        $('.ajax-form button[type="submit"]').removeAttr('clicked');
        $(this).attr('clicked', 'true');
    });

}

window.initAjaxForm = function () {

    $.each($('.ajax-form'), function (idx, ajaxForm) {

        var formIdentifier = $(ajaxForm).attr('data-identifier');
        var presetName = $(ajaxForm).attr('data-preset-name');
        var dimension = $(ajaxForm).attr('data-dimension');

        var formAjaxUrl = location.protocol + '//' + location.host + '/form/de/' + presetName + '/' + formIdentifier;

        $(ajaxForm).on('submit', 'form', function (e) {

            var formObj = $(this);
            var formURL = formObj.attr('action');
            var formData = new FormData(this);

            var trigger = $(this).find('button[clicked="true"]');

            formData.append($(trigger).attr('name'), $(trigger).attr('value'));

            $.ajax({

                url: formURL,
                type: 'POST',
                data: formData,
                mimeType: 'multipart/form-data',
                contentType: false,
                cache: false,
                processData: false,
                beforeSend: function () {

                    $(ajaxForm).find('.ajax-content').replaceWith('<i class=" ajax-content fa fa-spinner fa-pulse"></i>');

                },
                success: function (data) {

                    $(ajaxForm).find('.ajax-content').replaceWith(data);

                    initButtonClickHandler();

                    if ($(ajaxForm).find('.g-recaptcha').length) {
                        var captcha = $(ajaxForm).find('.g-recaptcha');
                        var sitekey = captcha.data('sitekey');
                        grecaptcha.render(captcha[0], {
                            "sitekey": sitekey
                        });
                    }

                }

            });

            e.preventDefault();

        });

        $(ajaxForm).load(formAjaxUrl + ' .ajax-content', function () {

            initButtonClickHandler();

            $(this).css("min-height", $(this).css("height"));

            if ($(this).find('.g-recaptcha').length) {
                var captcha = $(this).find('.g-recaptcha');
                var sitekey = captcha.data('sitekey');
                grecaptcha.render(captcha[0], {
                    "sitekey": sitekey
                });
            }

        });

    });

}

function reinitSwipers() {

    var swiper = [];
    $('.swiper-container').each(function (index) {
        var $el = $(this);
        this.swiper.destroy();

        $(this).find('.swiper-slide').each(function (index, elem) {
            $(this).data('index', index);
        });

        var options = {
            speed: 500,
            pagination: $el.find('.swiper-pagination')[0],
            paginationClickable: true,
            autoplay: false,
            autoplayDisableOnInteraction: false,
            simulateTouch: false
        };

        if (window.neos === undefined) {
            options.autoplay = 10000;
        }

        var swiperInstance = $el.swiper(options);
        this._swiper = swiperInstance;
        swiper[index] = swiperInstance;

        $el.parent('.mini-swiper').find('.swiper-button-prev').on('click', function () {
            swiper[index].slidePrev();
        });

        $el.parent('.mini-swiper').find('.swiper-button-next').on('click', function () {
            swiper[index].slideNext();
        });

        positionSwipperBullets();
    });

    $(window).resize(function () {
        updateSwipers();
        positionSwipperBullets();
    });
}